type Option = {
    label: string | number
    value: string | number
}

const CONDITION: Option[]= [
    {
        label: '是',
        value: 1
    },
    {
        label: '否',
        value: 0
    },
]

const PUBLISH_STATUS: Option[]= [
    {
        label: '立即发布',
        value: 1
    },
    {
        label: '定时发布',
        value: 0
    },
]

const STATUS: Option[]= [
    {
        value: 'release',	
        label: '已发布'
    },
    {
        value: 'unrelease',
        label: '未发布'
    },
    {
        label: '发布中',
        value: 'releasing'
    }, 
    {
        label: '下架',
        value: 'offselves'
    },
    {
        label: '保存',
        value: 'save'
    },
    {
        label: '删除',
        value: 'delete'
    }
]


const CONDITION_AND_ALL: Option[]= [
    {
        label: '全部',
        value: 2
    },
    ...CONDITION
]

export {
    CONDITION,
    CONDITION_AND_ALL,
    PUBLISH_STATUS,
    STATUS
}